
















































































































































































































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.fab-remove-item {
  width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  min-width: 30px !important;
}
